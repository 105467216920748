/** imports */
import * as React from 'react'
import { createRoot } from 'react-dom/client';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { setCookieToHash, getValueFromCookie, deleteCookies } from 'tsRoot/actions/cookie'
import { createUser,createProjectUser }      from 'tsRoot/actions/midworks-freelance/user'
import { notify }          from 'tsRoot/actions/notify'

import { Input }     from 'tsRoot/components/form/input'
import { Button }    from 'tsRoot/components/form/button'
import { Checkbox }  from 'tsRoot/components/form/checkbox'
import { Birthday }  from 'tsRoot/components/form/birthday'

import { checkValidation } from 'tsRoot/validator/validatorBase'
import Cookies from 'js-cookie'

import { useSnackbar } from 'notistack';

/** エクスポートするJSXのコンポーネント */
export const Main = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const pageName = 'UsersRegistration' // cookieのネームスペース分割に利用

  const [formError, setFormError]         = React.useState('')
  const [alreadySubmit, setAlreadySubmit] = React.useState(false)

  // 別々のReactDOMでは、stateが更新のたびに最初の描画時の値に戻るのでフォームでstateは使わない
  let inputs = {
    firstName: {value: '', validation: {require: true, lengthMax: 122}},
    firstNameKana: {value: '', validation: {require: true, lengthMax: 122, kana: true}},
    lastName: {value: '', validation: {require: true, lengthMax: 122}},
    lastNameKana: {value: '', validation: {require: true, lengthMax: 122, kana: true}},
    email: {value: '', validation: {require: true, lengthMax: 122, email: true}},
    phoneNumber: {value: '', validation: {require: true, lengthMin: 10, lengthMax: 20, numberAndHyphen: true}},
    birthday: {value: '', validation: {require: true, birthday: true}},
    mailDeliveryAgreementFlag: {value: '', validation: {}},
    agree: {value: '', validation: {}},
    formExpired: {value: false, validation: {}}
  }

  const initFormExpired = getValueFromCookie(`${pageName}-formExpired`) as any
  if(initFormExpired != 'false') deleteCookies(pageName) // 一定以上の時間が経ってからの再訪問時は値を消す
  setCookieToHash(inputs, pageName)

  async function submit() {
    setAlreadySubmit(true)

    // 各入力フォームの値の保持期間よりも短い時間でフォームの有効期限を設定して一部の値が欠落する状態を防ぐ
    if(getValueFromCookie(`${pageName}-formExpired`) != 'false') {
      notify(enqueueSnackbar, 'フォームの有効期限が切れています。画面を再読み込みしてください。', 'error')
      return false
    }

    if (!formErrorCheck()) return;

    if(!executeRecaptcha) {
      // rechapchaのセットアップができていない場合（token不備など）
      // この場合にエラーをそのまま出すのはかっこわるいので、メンテナンスと表示して問い合わせもらう
      notify(enqueueSnackbar, 'メンテナンス中です。運営事務局に問い合わせください。', 'error')
      return false
    }

    const reCaptchaToken = await executeRecaptcha();

    const rootElement = document.getElementById('be_form');
    const rootElementData = rootElement?.dataset as DOMStringMap
    const selfDomainRequest = rootElementData.frommypage != 'true'
    const query = new URLSearchParams(location.search)
    const projectId = query.get("project_id")
    const params = {
      first_name: inputs.firstName.value,
      last_name: inputs.lastName.value,
      first_name_kana: inputs.firstNameKana.value,
      last_name_kana: inputs.lastNameKana.value,
      name_from_salesforce: `${inputs.lastName.value} ${inputs.firstName.value}`,
      name_kana_from_salesforce: `${inputs.lastNameKana.value} ${inputs.firstNameKana.value}`,
      email: inputs.email.value,
      user_profile: {
        phone_number: inputs.phoneNumber.value.replace(/-/g, ''),
        occupation_id: null,
        current_job_status: null,
        desired_work_start_timing: null,
        birthday: inputs.birthday.value,
        mail_delivery_agreement_flag: inputs.mailDeliveryAgreementFlag.value ? 1 : 0
      },
      landing_param_token: Cookies.get('landing_param_token'),
      ga_utm_params: Cookies.get('ga_utm_params'),
      via_id: 2, // VIA_ID.MIDWORKS,
      from_ex_domain: selfDomainRequest,
      registration_href: location.href,

      recaptcha_token: reCaptchaToken
    }

    if (projectId)
      await createProjectUser(params, pageName, projectId, enqueueSnackbar)
    else
      await createUser(params, pageName, enqueueSnackbar)
  }

  const formErrorCheck = () => {
    setCookieToHash(inputs, pageName)

    // 各入力フォームの値の保持期間よりも短い時間でフォームの有効期限を設定して一部の値が欠落する状態を防ぐ
    if(inputs.formExpired.value != false) {
      setFormError('フォームの有効期限が切れています。画面を更新して再度実行してください。')
      return false
    }

    if (checkValidation(inputs.firstName.value, inputs.firstName.validation).length > 0
          || checkValidation(inputs.firstNameKana.value, inputs.firstNameKana.validation).length > 0
          || checkValidation(inputs.lastName.value, inputs.lastName.validation).length > 0
          || checkValidation(inputs.lastNameKana.value, inputs.lastNameKana.validation).length > 0
          || checkValidation(inputs.phoneNumber.value, inputs.phoneNumber.validation).length > 0
          || checkValidation(inputs.email.value, inputs.email.validation).length > 0
          || checkValidation(inputs.birthday.value, inputs.birthday.validation).length > 0
    ) {
      setFormError('入力内容を確認してください')
      return false
    }

    // 利用規約の同意チェック
    if (inputs.agree.value != 'true') {
      setFormError('利用規約の同意が必要です')
      return false
    }

    setFormError('')
    return true
  }

  React.useEffect(() => {
    document.cookie = `${pageName}-formExpired=false; max-age=3600; path=/`
    setCookieToHash(inputs, pageName)
    formErrorCheck()

    const firstNameElement = document.getElementById('be_form_first_name')
    const firstNameElementData = firstNameElement?.dataset

    if (firstNameElement && firstNameElementData) {
      createRoot(firstNameElement).render(
        <Input
          className={firstNameElementData.class || ''}
          pageName={pageName}
          inputName={"firstName"}
          type="text"
          errorCheckEnable={alreadySubmit}
          placeholder={firstNameElementData.placeholder || ''}
          errorMessageClass={firstNameElementData.errormessageclass || ''}
          errorFlug={firstNameElementData.errorflug || ''}
          validationRule={inputs.firstName.validation}
          errorCheck={formErrorCheck}
          label="名"
          require={true}
        />
      );
    }

    const firstNameKanaElement = document.getElementById('be_form_first_name_kana')
    const firstNameKanaElementData = firstNameKanaElement?.dataset

    if (firstNameKanaElement && firstNameKanaElementData) {
      createRoot(firstNameKanaElement).render(
        <Input
          className={firstNameKanaElementData.class || ''}
          pageName={pageName}
          inputName={"firstNameKana"}
          type="text"
          errorCheckEnable={alreadySubmit}
          placeholder={firstNameKanaElementData.placeholder || ''}
          errorMessageClass={firstNameKanaElementData.errormessageclass || ''}
          errorFlug={firstNameKanaElementData.errorflug || ''}
          validationRule={inputs.firstNameKana.validation}
          errorCheck={formErrorCheck}
          popItemOption='left'
          popItem={'カタカナで入力ください'}
          label="メイ"
          require={true}
        />
      );
    }

    const lastNameElement = document.getElementById('be_form_last_name')
    const lastNameElementData = lastNameElement?.dataset

    if (lastNameElement && lastNameElementData) {
      createRoot(lastNameElement).render(
        <Input
          className={lastNameElementData.class || ''}
          pageName={pageName}
          inputName={"lastName"}
          type="text"
          errorCheckEnable={alreadySubmit}
          placeholder={lastNameElementData.placeholder || ''}
          errorMessageClass={lastNameElementData.errormessageclass || ''}
          errorFlug={lastNameElementData.errorflug || ''}
          validationRule={inputs.lastName.validation}
          errorCheck={formErrorCheck}
          label="姓"
          require={true}
        />
      );
    }

    const lastNameKanaElement = document.getElementById('be_form_last_name_kana')
    const lastNameKanaElementData = lastNameKanaElement?.dataset

    if (lastNameKanaElement && lastNameKanaElementData) {
      createRoot(lastNameKanaElement).render(
        <Input
          className={lastNameKanaElementData.class || ''}
          pageName={pageName}
          inputName={"lastNameKana"}
          type="text"
          errorCheckEnable={alreadySubmit}
          placeholder={lastNameKanaElementData.placeholder || ''}
          errorMessageClass={lastNameKanaElementData.errormessageclass || ''}
          errorFlug={lastNameKanaElementData.errorflug || ''}
          validationRule={inputs.lastNameKana.validation}
          errorCheck={formErrorCheck}
          popItem={'カタカナで入力ください'}
          label="セイ"
          require={true}
        />
      );
    }

    const phoneNumberElement = document.getElementById('be_form_phonenumber')
    const phoneNumberElementData = phoneNumberElement?.dataset

    if (phoneNumberElement && phoneNumberElementData) {
      createRoot(phoneNumberElement).render(
        <Input
          className={phoneNumberElementData.class || ''}
          pageName={pageName}
          inputName={"phoneNumber"}
          type="tel"
          errorCheckEnable={alreadySubmit}
          placeholder={phoneNumberElementData.placeholder || ''}
          errorMessageClass={phoneNumberElementData.errormessageclass || ''}
          errorFlug={phoneNumberElementData.errorflug || ''}
          validationRule={inputs.phoneNumber.validation}
          errorCheck={formErrorCheck}
          popItem={'半角数字で入力ください'}
          label="電話番号"
          require={true}
        />
      );
    }

    const emailElement = document.getElementById('be_form_email')
    const emailElementData = emailElement?.dataset

    if (emailElement && emailElementData) {
      createRoot(emailElement).render(
        <Input
          className={emailElementData.class || ''}
          pageName={pageName}
          inputName={"email"}
          type="text"
          errorCheckEnable={alreadySubmit}
          placeholder={emailElementData.placeholder || ''}
          errorMessageClass={emailElementData.errormessageclass || ''}
          errorFlug={emailElementData.errorflug || ''}
          validationRule={inputs.email.validation}
          errorCheck={formErrorCheck}
          popItem={'半角英数字で入力ください'}
          label="メールアドレス"
          require={true}
        />
      );
    }

    const birthdayElement = document.getElementById('be_form_birthday')
    const birthdayElementData = birthdayElement?.dataset

    if (birthdayElement && birthdayElementData) {
      createRoot(birthdayElement).render(
        <Birthday
          className={birthdayElementData.class || ''}
          pageName={pageName}
          inputName={"birthday"}
          errorCheckEnable={alreadySubmit}
          placeholder={birthdayElementData.placeholder || ''}
          errorMessageClass={birthdayElementData.errormessageclass || ''}
          errorFlug={birthdayElementData.errorflug || ''}
          validationRule={inputs.birthday.validation}
          errorCheck={formErrorCheck}
          popItem={<>入力または選択ください。<br/>スラッシュの入力は不要です。</>}
          label="生年月日"
          require={true}
        />
      );
    }

    const checkElement = document.getElementById('be_form_agree_check')
    const checkLableElement = document.getElementById('be_form_agree_check_label')
    const checkElementData = checkElement?.dataset

    if(checkElement && checkElementData) {
      createRoot(checkElement).render(
        <Checkbox
          className={checkElementData.class || ''}
          pageName={pageName}
          errorMessageClass={checkElementData.errormessageclass || ''}
          inputName="agree"
          inputElements={checkElement}
          labelElements={checkLableElement}
          errorCheck={formErrorCheck}
          registrationAgreeLabel={true}
        />
      );
    }

    const checkDelivaryEmailElement = document.getElementById('be_form_check_delivary_email')
    const checkDelivaryEmailLableElement = document.getElementById('be_form_check_delivary_email_label')
    const checkDelivaryEmailElementData = checkDelivaryEmailElement?.dataset

    if(checkDelivaryEmailElement && checkDelivaryEmailElementData) {
      createRoot(checkDelivaryEmailElement).render(
        <Checkbox
          className={checkDelivaryEmailElementData.class || ''}
          pageName={pageName}
          errorMessageClass={checkDelivaryEmailElementData.errormessageclass || ''}
          inputName="admail"
          inputElements={checkDelivaryEmailElement}
          labelElements={checkDelivaryEmailLableElement}
          checked={true}
          errorCheck={formErrorCheck}
          errorDisable={true}
        />
      );
    }
  }, []);

  // recaptchaの取得関数が非同期で変数に格納されるため、submitボタンはその取得を待ってから描画する
  React.useEffect(() => {
    const submitElement = document.getElementById('be_form_submit')
    const submitElementData = submitElement?.dataset

    if(submitElement && submitElementData && executeRecaptcha && formErrorCheck()){
      createRoot(submitElement).render(
        <Button
          onClick={() => submit()}
          className={submitElementData.class || ''}
          buttonName="ga-cv"
          buttonText={submitElementData.value || ''}
          type='button'
          active={true}
        />
      );
    } else if (submitElement && submitElementData && executeRecaptcha) {
      createRoot(submitElement).render(
        <Button
          onClick={() => submit()}
          className={submitElementData.class || ''}
          buttonName="ga-cv"
          buttonText={submitElementData.value || ''}
          type='button'
          active={false}
        />
      );
    }

  }, [executeRecaptcha, formError]);

  // useEffectのReactDOMでviewの一部分だけ書き換えるようにreactを使っているため、ここではreturnを空で返す
  return (<></>);
}
