/*****************************************************************************************
 * エントリーポイントファイル
 *
 * 新規のエントリーポイントの追加時にはwebpackの再起動が必要になるので注意
 ****************************************************************************************/

/** imports */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// our components
import { CONST } from 'tsRoot/constants'
import { Main } from './main'

import { SnackbarProviderComponent } from 'tsRoot/components/snackbarProviderComponent'

/** DOMへのrender */
// <div id='be_form'/> のコンポーネントが必要なことに注意
const rootElement = document.getElementById('be_form');

if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <GoogleReCaptchaProvider
      reCaptchaKey={CONST.RECAPTCHA.SITE_KEY}
      language="ja"
      useRecaptchaNet={false}
      useEnterprise={false}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined  // optional, default undefined
      }}
    >
      <SnackbarProviderComponent>
        <Main />
      </SnackbarProviderComponent>
    </GoogleReCaptchaProvider>
  )
}
